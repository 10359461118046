<template>
  <v-app>
    <v-main class="primary">
      <v-container fill-height fluid>
        <v-row class="space-10"></v-row>
        <v-row justify="center">
          <v-col
            class="logo-flex"
            cols="12"
            xs="12"
            sm="8"
            md="6"
            lg="4"
            xl="3"
          >
            <img class="logo-center" src="../assets/images/logo/logo.svg" />
          </v-col>
        </v-row>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" xs="12" sm="8" md="6" lg="4" xl="3">
              <v-text-field
                v-on:keyup.enter="login"
                @focus="showError = false"
                solo
                flat
                clearable
                rounded
                color="secondary"
                label="Username"
                prepend-inner-icon="mdi-account-circle"
                v-model="username"
              />

              <v-text-field
                v-on:keyup.enter="login"
                @focus="showError = false"
                solo
                flat
                clearable
                rounded
                type="password"
                color="secondary"
                label="Parola"
                prepend-inner-icon="mdi-asterisk"
                v-model="password"
              />

              <v-alert v-if="showError" border="top" colored-border type="error"
                >Usernameul sau parola sunt invalide</v-alert
              >
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="2"
              ><v-btn
                @click="login()"
                color="secondary"
                block
                depressed
                rounded
                :loading="isLoading"
              >
                Logheaza-te
              </v-btn></v-col
            >
          </v-row>
        </v-container>
        <v-row class="space-10"></v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      showError: false,
      username: "",
      password: "",
    };
  },
  computed: {
    ...mapState("authentication", {
      isAuthenticated: "isAuthenticated",
    }),
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "Results" });
    }
  },
  methods: {
    async login() {
      if (!this.username || !this.password) {
        this.showError = true;
        return;
      }
      this.isLoading = true;
      try {
        var response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/users/login/web`,
          {
            username: this.username,
            password: this.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        await this.$store.dispatch(
          "authentication/authenticate",
          response.data
        );
        if (response.data.oldPassword) {
          this.$router.push({ name: "Profile" });
        } else {
          this.$router.push({ name: "Results" });
        }
      } catch (_) {
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.space-10 {
  height: 10%;
}

.logo-flex {
  text-align: center;
}

.logo-center {
  display: inline-block;
}
</style>
